// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"
import { isMobile } from "react-device-detect"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import SEO from "../../components/seo"
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import birdsImg from "./assets/birds.png"
import nestImg from "./assets/nest.png"
import artImg from "./assets/art.png"
import artLinesImg from "./assets/art-lines.png"
import tfScreenImg from "./assets/tf-screen.png"
import designScreenImg from "./assets/design-screen.png"
import sealImg from "./assets/seal.png"
import waterMaskImg from "./assets/water-mask.png"

// Sprites
import musicImg from "./assets/sprites/music.png"
import lightsImg from "./assets/sprites/lights.png"
import artHeadImg from "./assets/sprites/art-head.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 6,
  preHeading: "Sustainable Industry",
  heading: "This must be the place where we create and recreate.",
  content:
    "Where we nurture cottage craft and national industry. Where ideas and innovation flourish in one-bench workshops and full-scale factories. Here, we formed centres of excellence from the crucible of a crisis. We pour our passion into products that last and direct our intellect towards digital dynamism. We weave sustainability into the warp and justice into the weft of our work. Here, we encourage experiments, invest in invention and cherish our capacity to create.",
  slug: "sustainable-industry",
  nextSlug: "out-of-office",
}

const SustainableIndustry = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -80, y: 176 },
    }),
    fg = useRef({
      parallax: 1,
      src: fgImg,
      el: null,
      offset: { x: -80, y: 1197 },
    }),
    birds = useRef({
      parallax: 0.2,
      src: birdsImg,
      el: null,
      offset: { x: 2358, y: 336 },
    }),
    nest = useRef({
      parallax: 0.2,
      src: nestImg,
      el: null,
      offset: { x: 2359, y: 355 },
    }),
    music = useRef({
      parallax: 0.2,
      src: musicImg,
      el: null,
      offset: { x: 2355, y: 180 },
      size: { w: 60, h: 130 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 8,
      loop: true,
      paused: true,
    }),
    lights = useRef({
      parallax: 0.5,
      src: lightsImg,
      el: null,
      offset: { x: 303, y: 184 },
      size: { w: 610, h: 300 },
      currentSprite: 1,
      rowCount: 6,
      spriteFrames: 18,
      slowFactor: 3,
      loop: true,
    }),
    tfScreen = useRef({
      parallax: 0.5,
      src: tfScreenImg,
      el: null,
      offset: { x: 401, y: 840 },
    }),
    designScreen = useRef({
      parallax: 0.5,
      src: designScreenImg,
      el: null,
      offset: { x: 400, y: 839 },
    }),
    art = useRef({
      parallax: 1.2,
      src: artImg,
      el: null,
      offset: { x: 2884, y: 1200 },
    }),
    artLines = useRef({
      parallax: 1.2,
      src: artLinesImg,
      el: null,
      offset: { x: 3055, y: 1890 },
    }),
    artHead = useRef({
      parallax: 1.2,
      src: artHeadImg,
      el: null,
      offset: { x: 3034, y: 1020 },
      size: { w: 216, h: 216 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    seal = useRef({
      parallax: 1,
      src: sealImg,
      el: null,
      offset: { x: 868, y: 1700 },
    }),
    waterMask = useRef({
      parallax: 1,
      src: waterMaskImg,
      el: null,
      offset: { x: 866, y: 1850 },
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    birds.current,
    nest.current,
    music.current,
    tfScreen.current,
    designScreen.current,
    mg.current,
    lights.current,
    fg.current,
    art.current,
    artLines.current,
    artHead.current,
    seal.current,
    waterMask.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([lights.current, artHead.current, music.current])

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 1888,
    y: 1369,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  const showSeal = direction => {
    if (direction === "in") {
      gsap.to(seal.current.offset, {
        y: 1590,
        duration: 1,
        ease: "power1.inOut",
      })
    } else {
      gsap.to(seal.current.offset, {
        y: 1700,
        duration: 1,
        ease: "power1.inOut",
      })
    }
  }

  const showBirds = direction => {
    if (direction === "in" && music.current.paused) {
      gsap.to(birds.current.offset, {
        x: 2355,
        y: 320,
        duration: 0.5,
        ease: "power1.out",
      })
      music.current.paused = false
    }
  }

  const changeTab = direction => {
    if (direction === "in") {
      designScreen.current.hidden = true
    } else {
      designScreen.current.hidden = false
    }
  }

  // Initial animations
  const startingAnimations = () => {
    gsap.fromTo(
      artLines.current.offset,
      { x: 3050 },
      { x: 3060, duration: 0.14, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      art.current.offset,
      { y: 1160 },
      { y: 1200, duration: 2, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      artLines.current.offset,
      { y: 1850 },
      { y: 1890, duration: 2, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
    gsap.fromTo(
      artHead.current.offset,
      { y: 980 },
      { y: 1020, duration: 2, ease: "power1.inOut", repeat: -1, yoyo: true }
    )
  }

  // Animate in
  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      startingAnimations()
      if (isMobile) showBirds("in")
    }, 1000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <SEO title={pageData.heading} />
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: showSeal,
              x: 820,
              y: 1550,
              w: 440,
              h: 450,
            },
            {
              event: changeTab,
              x: 320,
              y: 716,
              w: 308,
              h: 368,
            },
            {
              event: showBirds,
              x: 2100,
              y: 100,
              w: 410,
              h: 350,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default SustainableIndustry
